import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { fetchSetting } from '../ApiService';

const Footer = () => {
  const [setting, setSetting] = useState([]);

  useEffect(() => {
    const fetchData=async()=>{
      const response=await fetchSetting();
      setSetting(response);
    }
    fetchData();
  }, []);

  return (
    <footer id="footer">
      {/* FOOTER TOP */}
      <div className="footer_top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <p>{setting?.alamat || 'Loading address...'}</p>
              <p>
                Phone: <a href={`tel:${setting?.telp || ''}`} className="brown">{setting?.telp || 'Loading phone...'}</a>, 
                Email: <a href={`mailto:${setting?.email || ''}`} className="brown">{setting?.email || 'Loading email...'}</a>
              </p>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12">
              <div className="social">
                <div className="social-content">
                  <p style={{ marginBottom: '0px', marginTop: '5px', letterSpacing: '3px' }}><b>FOLLOW US</b></p>
                  <a href={setting?.fb || '#'} target="_blank" rel="noopener noreferrer" aria-label="Follow us on Facebook">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href={setting?.ig || '#'} target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a href={setting?.yt || '#'} target="_blank" rel="noopener noreferrer" aria-label="Follow us on YouTube">
                    <FontAwesomeIcon icon={faYoutube} style={{ fontSize: '24px' }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END / FOOTER TOP */}

      <div className="footer_bottom">
        <div className="container">
          <p>&copy; {new Date().getFullYear()} All rights reserved - {setting?.nama || 'Loading company name...'}</p>
        </div>
      </div>

      <section className="booking-mobile">
        <a href={setting?.booking_link || '#'} target="_blank" rel="noopener noreferrer">BOOK NOW</a>
      </section>
    </footer>
  );
};

export default Footer;
