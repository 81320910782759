import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { fetchAllMenu, fetchSetting } from "../ApiService";

const Header = ({ isCMS = false }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [submenuActive, setSubmenuActive] = useState({});
    const [menus, setMenus] = useState([]);
    const [settings, setSettings]=useState([]);
    const location = useLocation();

    // Ambil data menu dari API
    useEffect(() => {
        const getMenus = async () => {
            const response = await fetchAllMenu();
            
            const hasil=response.data.data;
            const filtereddata=hasil?.filter((menu)=> menu.status ===1)
                                    .sort((a,b)=>a.order_by - b.order_by);
            setMenus(filtereddata);

           
        };
        getMenus();

        const getBookLink=async()=>{
            const response=await fetchSetting();                     
            setSettings(response);
        };
        getBookLink();
    }, []);

    
    // Filter menu utama (type: 1)
    const mainMenus = menus?.filter(menu => menu.type === 1);


    // Filter submenu (type: 2) dan kelompokkan berdasarkan menu_id
    const subMenus = menus.filter(menu => menu.type === 2);

    

    const groupedSubMenus = subMenus.reduce((acc, submenu) => {
        if (!acc[submenu.menu_id]) acc[submenu.menu_id] = [];
        acc[submenu.menu_id].push(submenu);
        return acc;
    }, {});

    // Handle window resize
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 991);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Toggle menu utama (mobile)
    const toggleMenu = () => {
        setMenuActive(prev => {
            if (prev) {
                setSubmenuActive({}); // Reset submenu saat menu utama ditutup
            }
            return !prev;
        });
    };


    const toggleSubmenu = (menuId) => {
        if (isMobile) {
            setSubmenuActive(prev => {
                // Jika menu yang diklik sudah terbuka, tutup semuanya
                if (prev[menuId]) {
                    return {}; // Reset state untuk menutup semua submenu
                }

                // Jika menu yang diklik belum terbuka, tutup semua lalu buka yang baru
                return { [menuId]: true };
            });
        }
    };


    // Handle menu click (menutup menu setelah klik link di mobile)
    const handleLinkClick = () => {
        if (isMobile) {
            setMenuActive(false);
        }
    };


    return (

        <header id="header">

            <div className="header_top">
                <div className="container-fluid">
                    <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div className="col-xs-12 col-sm-12">
                            <div className="header_logo">
                                <Link to="/" onClick={handleLinkClick}>
                                    <img src="images/padma-resident-logo.png" alt="Padma Resident" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* HEADER MENU */}
            <div className={isMobile ? "header_mobile" : "header_content"}>
                <div className="container">
                    <nav className={`header_menu ${menuActive ? "active" : ""}`}>
                        <ul className="menu">
                            <>
                                {mainMenus.map(mainMenu => {
                                    const isActiveMainMenu = (location.pathname + location.search === mainMenu.path)

                                    return (
                                        <li
                                            key={mainMenu.id}
                                            className={`menu-item ${isActiveMainMenu ? "active" : ""} ${groupedSubMenus[mainMenu.id] ? "has-submenu" : ""}`}
                                        >
                                            {/* Mode Mobile */}
                                            {isMobile ? (
                                                <div className="container">
                                                    <div className="row align-items-center">
                                                        {/* Kolom untuk teks menu */}
                                                        <div className="col-10">
                                                            <Link
                                                                to={mainMenu.path}
                                                                target={mainMenu.destination === 1 ? "_blank" : "_self"}
                                                                className="menu-text"
                                                                onClick={handleLinkClick}
                                                            >
                                                                {mainMenu.tittle}
                                                            </Link>
                                                        </div>

                                                        {/* Kolom untuk ikon (hanya di mobile) */}
                                                        {groupedSubMenus[mainMenu.id] && (
                                                            <div className="col-2 text-end">
                                                                <FontAwesomeIcon
                                                                    icon={faChevronRight}
                                                                    className={`menu-icon ${submenuActive[mainMenu.id] ? "rotate" : ""}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        toggleSubmenu(mainMenu.id);
                                                                    }}
                                                                    style={{ cursor: "pointer", fontSize: "1.5rem", marginTop: "10px" }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Submenu di mode mobile */}
                                                    {groupedSubMenus[mainMenu.id] && (
                                                        <ul className={`sub-menu ${submenuActive[mainMenu.id] ? "show" : ""}`} style={{ marginLeft: "20px" }}>
                                                            {groupedSubMenus[mainMenu.id].map(subMenu => {
                                                                const isActiveSubMenu = location.pathname + location.search === subMenu.path;

                                                                return (
                                                                    <li key={subMenu.id} className={isActiveSubMenu ? "active" : ""}>
                                                                        <Link
                                                                            to={subMenu.path}
                                                                            target={subMenu.destination === 1 ? "_blank" : "_self"}
                                                                            className="submenu-link"
                                                                            onClick={handleLinkClick}
                                                                        >
                                                                            {subMenu.tittle}
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    )}
                                                </div>
                                            ) : (
                                                <>
                                                    <Link
                                                        to={mainMenu.path}
                                                        target={mainMenu.destination === 1 ? "_blank" : "_self"}
                                                        className={isActiveMainMenu ? "active" : ""}
                                                        onClick={handleLinkClick}
                                                    >
                                                        {mainMenu.tittle}
                                                        {groupedSubMenus[mainMenu.id] && mainMenu.tittle !== "Special Offers" && (
                                                            <FontAwesomeIcon icon={faChevronRight} />
                                                        )}
                                                    </Link>

                                                    {/* Render submenu jika ada */}
                                                    {groupedSubMenus[mainMenu.id] && (
                                                        <ul className="sub-menu">
                                                            {groupedSubMenus[mainMenu.id].map(subMenu => {
                                                                const isActiveSubMenu = location.pathname + location.search === subMenu.path;

                                                                return (
                                                                    <li key={subMenu.id} className={isActiveSubMenu ? "active" : ""}>
                                                                        <Link
                                                                            to={subMenu.path}
                                                                            target={subMenu.destination === 1 ? "_blank" : "_self"}
                                                                            className={isActiveSubMenu ? "active" : ""}
                                                                            onClick={handleLinkClick}
                                                                        >
                                                                            {subMenu.tittle}
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    )}
                                                </>
                                            )}
                                        </li>
                                    );
                                })}

                                <li className="book">
                                    <Link to={settings?.booking_link} className="awe-btn awe-btn-13" target="_blank" onClick={handleLinkClick}>Book Now</Link>
                                </li>
                            </>
                        </ul>
                    </nav>

                    {/* MENU BAR */}
                    {isMobile && (
                        <span className={`menu-bars ${menuActive ? "active" : ""}`} onClick={toggleMenu}>
                            <span></span>
                        </span>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;

