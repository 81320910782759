import React from 'react';
import { Outlet } from 'react-router-dom'; // Impor Outlet
import Header from '../components/Header';
import Footer from '../components/Footer';

const LandingLayout = () => {
  return (
    <div className="page-wrap">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default LandingLayout;