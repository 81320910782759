import { BrowserRouter as Router, Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { useEffect, useState, lazy, Suspense } from "react";
import { cekToken } from "./ApiService";
import "./App.css";

import Page from "./components/Page";
import LandingLayout from "./layouts/LandingLayout";
import StayLonger from "./pages/StayLonger";
// import FAQSection from "./pages/Faq";
const DashboardLayout = lazy(() => import("./layouts/CmsLayout"));
// const LandingLayout = lazy(() => import("./layouts/LandingLayout"));
// const Page = lazy(() => import("./components/Page"));
// const StayLonger = lazy(() => import("./pages/StayLonger"));
const FAQSection = lazy(() => import("./pages/Faq"));

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/cms/Mydash"));
const KategoriItem = lazy(() => import("./pages/cms/Kategori"));
const AddTab = lazy(() => import("./pages/cms/tab/addTab"));
const Tab = lazy(() => import("./pages/cms/tab/Tab"));
const EditTab = lazy(() => import("./pages/cms/tab/editTab"));
const Blog = lazy(() => import("./pages/cms/blog/Blog"));
const EditBlog = lazy(() => import("./pages/cms/blog/blogForm"));
const MenuTable = lazy(() => import("./pages/cms/MenuTable"));
const FaqKomponen = lazy(() => import("./pages/cms/faq/FaqKomponen"));
const FaqKategori = lazy(() => import("./pages/cms/faq/FaqKategori"));
const UserTable = lazy(() => import("./pages/cms/User"));
const Setting = lazy(()=>import('./pages/cms/Setting'));




// Komponen Loading
const LoadingScreen = () => <div>Loading...</div>;

// Komponen Protected Route
const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      const authStatus = token ? await cekToken(token) : false;
      setAuth(authStatus);
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  if (isLoading) return <LoadingScreen />;
  return auth ? children : <Navigate to="/login" replace />;
};

// Komponen Public Route
const PublicRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      const authStatus = token ? await cekToken(token) : false;
      setAuth(authStatus);
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  if (isLoading) return <LoadingScreen />;
  return auth ? <Navigate to="/dashboard" replace /> : children;
};

// Komponen NotFound
const NotFound = () => <center>Halaman Tidak Ditemukan</center>;

// Komponen PageComponent untuk menangani query parameter
const PageComponent = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("path") || "home";
  return <Page slug={page} />;
};

// Komponen utama App
const App = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {/* Halaman Login (jika sudah login, langsung redirect ke dashboard) */}
          <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />

          {/* Rute Publik */}
          <Route element={<LandingLayout />}>
            <Route path="/" element={<PageComponent />} />
            <Route path="/stay" element={<StayLonger />} />
            <Route path="/faq" element={<FAQSection />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          {/* Rute yang memerlukan autentikasi */}
          <Route element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
            <Route path="/cms" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/kategori" element={<KategoriItem />} />
            <Route path="/menus" element={<MenuTable />} />
            <Route path="/pages" element={<Blog />} />
            <Route path="/tabs/edit/:id" element={<EditTab />} />
            <Route path="/tabs/add/:id" element={<AddTab />} />
            <Route path="/tabs/:id" element={<Tab />} />
            <Route path="/faqs" element={<FaqKomponen />} />
            <Route path="/faqc" element={<FaqKategori />} />
            <Route path="/pages/add" element={<EditBlog mode="add" />} />
            <Route path="/pages/edit/:id" element={<EditBlog mode="edit" />} />
            <Route path="/users" element={<UserTable />} />
            <Route path="/setting" element={<Setting />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
