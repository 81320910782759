import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StayLonger = () => {
    // State untuk tab aktif
    const [activeTab, setActiveTab] = useState("legian");

    // Fungsi untuk menangani perubahan tab
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <>
            <section className="section-room-detail">
                <div className="container">
                    <div className="room-detail_tab">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="text">
                                    <h2 className="heading" style={{ textAlign: "center" }}>
                                        Stay Longer and Earn the Rewards
                                    </h2>
                                    <hr
                                        className="lebar"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <ul className="room-detail_tab-header">
                                    {[
                                        { id: "1", label: "Padma Resort Legian" },
                                        { id: "2", label: "Padma Resort Ubud" },
                                        { id: "3", label: "Padma Hotel Bandung" },
                                        { id: "4", label: "Padma Hotel Semarang" },
                                        { id: "5", label: "Resinda Hotel Karawang" },
                                    ].map((tab) => (
                                        <li
                                            key={tab.id}
                                            className={activeTab === tab.id ? "active" : ""}
                                        >
                                            <a
                                                onClick={() => handleTabClick(tab.id)}
                                                className="tab-button"
                                            >
                                                {tab.label}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-8">
                                <div className="room-detail_tab-content">
                                    {activeTab === "1" && (
                                        <div className="tab-pane active">
                                            <div className="room-detail_overview">
                                                <p>
                                                    Extend your stay and experience more at any Padma
                                                    Hotels destination. Enjoy this exclusive offer
                                                    tailored for <span className="brown">Padma Royals</span> and{" "}
                                                    <span className="brown">Padma Ambassadors</span> by
                                                    booking directly through our{" "}
                                                    <a
                                                        className="brown"
                                                        href="https://www.padmahotels.com/padma-hotels-app.php"
                                                    >
                                                        Padma Hotels App
                                                    </a>{" "}
                                                    or official website.
                                                </p>
                                                <br />
                                                <ul>
                                                    <li>Two Themed Buffet Dinner at Donbiu Restaurant</li>
                                                    <li>Two 30-minute back & shoulder massage</li>
                                                    <li>Laundry coupon for five pieces of clothing</li>
                                                </ul>
                                                <br />
                                                <p>
                                                    <em>
                                                        This offer is valid for a minimum stay of 7
                                                        consecutive nights, with the option to extend for up
                                                        to three sets (7, 14, or 21 nights).
                                                    </em>
                                                </p>
                                                <br />
                                                <p>
                                                    For more information, please email to{" "}
                                                    <a
                                                        href="mailto:padmaresident@padmahotels.com"
                                                        className="brown"
                                                    >
                                                        padmaresident@padmahotels.com
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    )}

                                    {activeTab === "2" && (
                                        <div className="tab-pane">
                                            <div className="room-detail_overview">
                                                <p>
                                                    Extend your stay and experience more at any Padma
                                                    Hotels destination. Enjoy this exclusive offer
                                                    tailored for <span className="brown">Padma Royals</span> and{" "}
                                                    <span className="brown">Padma Ambassadors</span> by
                                                    booking directly through our{" "}
                                                    <a
                                                        className="brown"
                                                        href="https://www.padmahotels.com/padma-hotels-app.php"
                                                    >
                                                        Padma Hotels App
                                                    </a>{" "}
                                                    or official website.
                                                </p>
                                                <br />
                                                <ul>
                                                    <li>Two set menu lunch at The Puhu Restaurant</li>
                                                    <li>Laundry coupon for five pieces of clothing</li>
                                                </ul>
                                                <br />
                                                <p>
                                                    <em>
                                                        This offer is valid for a minimum stay of 5
                                                        consecutive nights, with the option to extend for up
                                                        to three sets (5, 10, or 15 nights).
                                                    </em>
                                                </p>
                                                <br />
                                                <p>
                                                    For more information, please email to{" "}
                                                    <a
                                                        href="mailto:padmaresident@padmahotels.com"
                                                        className="brown"
                                                    >
                                                        padmaresident@padmahotels.com
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === "3" && (
                                        <div className="tab-pane" id="bandung">

                                            <div className="room-detail_overview">
                                                <p>Extend your stay and experience more at any Padma Hotels destination. Enjoy this exclusive offer tailored for <span className="brown">Padma Royals</span> and <span className="brown">Padma Ambassadors</span> by booking directly through our <a className="brown" href="https://www.padmahotels.com/padma-hotels-app.php">Padma Hotels App</a> or official website.</p>
                                                <br />
                                                <ul>
                                                    <li>Complimentary transport, from and to Bandung Bus & Train Station/Travel Shuttle</li>
                                                    <li>IDR 250,000 F&B credit/room for Padma Royals*</li>
                                                    <li>IDR 350,000 F&B credit/room for Padma Ambassadors*</li>
                                                </ul>
                                                <br />
                                                <p><em>This offer is valid for a minimum stay of 3 consecutive nights, with the option to extend for up to three sets (3, 6, or 9 nights).</em></p>
                                                <br />
                                                <p>For more information, please email to <a href="mailto:padmaresident@padmahotels.com" className="brown">padmaresident@padmahotels.com</a></p>
                                            </div>

                                        </div>
                                    )}
                                    {activeTab === "4" && (
                                        <div className="tab-pane" id="karawang">

                                            <div className="room-detail_overview">
                                                <p>Extend your stay and experience more at any Padma Hotels destination. Enjoy this exclusive offer tailored for <span class="brown">Padma Royals</span> and <span class="brown">Padma Ambassadors</span> by booking directly through our <a class="brown" href="https://www.padmahotels.com/padma-hotels-app.php">Padma Hotels App</a> or official website.</p>
                                                <br/>
                                                <ul> 
                                                <li>IDR 200,000 F&B credit/room</li>
                                        
                                                </ul>
                                                <br/>
                                                <p><em>This offer is valid for a minimum stay of 3 consecutive nights, with the option to extend for up to three sets (3, 6, or 9 nights).</em></p>
                                                <br/>
                                                <p>For more information, please email to <a href="mailto:padmaresident@padmahotels.com" class="brown">padmaresident@padmahotels.com</a></p>
                                            </div> 

                                        </div>
                                    )}
                                    {activeTab === "5" && (
                                    <div className="tab-pane" id="semarang">

                                        <div className="room-detail_overview">
                                            <p>Extend your stay and experience more at any Padma Hotels destination. Enjoy this exclusive offer tailored for <span class="brown">Padma Royals</span> and <span class="brown">Padma Ambassadors</span> by booking directly through our <a class="brown" href="https://www.padmahotels.com/padma-hotels-app.php">Padma Hotels App</a> or official website.</p>
                                            <br/>
                                            <ul> 
                                            <li>IDR 300,000 F&B credit/room</li>
                                        
                                            </ul>
                                            <br/>
                                            <p><em>This offer is valid for a minimum stay of 3 consecutive nights, with the option to extend for up to three sets (3, 6, or 9 nights).</em></p>
                                            <br/>
                                            <p>For more information, please email to <a href="mailto:padmaresident@padmahotels.com" class="brown">padmaresident@padmahotels.com</a></p>
                                        </div> 

                                    </div>
                                    )}

                                    {/* Tambahkan konten lain untuk Bandung, Semarang, dan Karawang */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default StayLonger;
