import React from "react";

const CustomArrow = ({ onClick, direction }) => {
  return (
    <div
      className={`custom-arrow ${direction}`}
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        cursor: "pointer",
        fontSize: "24px",
        color: "rgba(250,249,249,0.2)",
        backgroundColor: "rgba(250, 249, 249, 0)",
        padding: "10px",
        borderRadius: "25%",
        [direction === "prev" ? "left" : "right"]: "20px",
      }}
    >
      {direction === "prev" ? "‹" : "›"}
    </div>
  );
};

export default CustomArrow;