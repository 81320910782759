import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import { fetchBlogBySlug, fetchFaqCategory, fetchTab, fetchFaq } from "../ApiService";
import CustomArrow from "./arrowSlider";
import parse from "html-react-parser";
// import loading from "./LoadingSpinner";
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"; // Warning/Error icon


const Page = ({ slug }) => {


  const [openCollapse, setOpenCollapse] = useState(null);
  const [blogs, setBlogs] = useState(null);
  const [faq, setFaq] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [id, setId] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //reset nilai tab ketika membuka halaman baru
  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs[0].id.toString());
    } else {
      setActiveTab(null);
    }
  }, [tabs]);

  // ambil data blog berdasarkan slug
  useEffect(() => {
    let isMounted = true;
    const getBlogs = async () => {
      setLoading(true);
      setError(null);

      try {
        const data = await fetchBlogBySlug(slug);
        if (isMounted) {
          if (data?.data) {
            setBlogs(data.data);
            setSlides(data.data.slides || []);
            setId(data.data.id);
            if (data.data.category_id === "3") {
              fetchFaqData();
            }
          } else {
            setError("Data Halaman tidak ditemukan");
          }
        }
      } catch (error) {
        if (isMounted) setError("Terjadi kesalahan dalam mengambil data");
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    getBlogs();

    return () => {
      isMounted = false; // Mencegah update state setelah unmount
    };
  }, [slug]);

  // Ambil data tab berdasarkan ID blog
  useEffect(() => {
    if (!id) return;
    let isMounted = true;

    const getTabs = async () => {
      setError(null); // Reset error sebelum request baru

      try {
        const data = await fetchTab(id);
        if (isMounted) {
          if (data?.data) {
            setTabs(data.data);
          } else {
            setError("Data tidak ditemukan");
          }
        }
      } catch (error) {
        if (isMounted) setError("Terjadi kesalahan dalam mengambil data");
      }
    };

    getTabs();

    return () => {
      isMounted = false;
    };
  }, [id]);
  // console.log(tabs);

  // Ambil data FAQ ketika category id = 3
  const fetchFaqData = async () => {
    let isMounted = true;
    try {
      const faqData = await fetchFaqCategory();
      if (isMounted) {
        setFaq(faqData?.data || []); // Simpan data FAQ ke state
      }
    } catch (error) {
      console.error("Gagal mengambil data FAQ:", error);
    }
  };

  // console.log(faq);
  // Membagi tab menjadi 2 kolom ketika faq
  const { leftColumn, rightColumn } = Array.isArray(faq) && faq.length > 0
    ? faq.reduce(
      (acc, item, index) => {
        if (index % 2 === 0) {
          acc.leftColumn.push(item);
        } else {
          acc.rightColumn.push(item);
        }
        return acc;
      },
      { leftColumn: [], rightColumn: [] }
    )
    : { leftColumn: [], rightColumn: [] };


  //mengatir activeTab ke ID tab pertama
  useEffect(() => {
    if (blogs?.category_id === "2") {
      // console.log("Tabs:", tabs);
      // console.log("Active Tab:", activeTab);
      if (tabs.length > 0 && activeTab === null) {
        setActiveTab(tabs[0].id.toString()); // Pastikan tab pertama di-set
      }
    }
  }, [tabs, activeTab]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };


  // Set judul halaman
  useEffect(() => {
    if (blogs?.title) {
      document.title = blogs.title || "Padma Resident";
    }
  }, [blogs?.title]);
  // console.log(blogs?.slide);


  // Fungsi untuk menangani toggle collapse
  const handleCollapse = (id) => {
    setOpenCollapse(openCollapse === id ? null : id);
  };

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    arrows: true,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ClipLoader color="#36d7b7" size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        color: '#D32F2F' // Warna merah untuk error
      }}>
        <FontAwesomeIcon icon={faTriangleExclamation} size="3x" style={{ marginBottom: "10px" }} />
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>{error}</p>
      </div>
    );
  }

  if (blogs?.category_id === "1") {
    return (
      <>
        {slides.length > 0 && (
          <section className="section-slider">
            <Slider {...settings}>
              {slides.map((slide, index) => (
                <div key={index} className="slide">
                  <img
                    src={slide.image_src}
                    alt={`Slide ${index + 1}`}
                    className="slide-image"
                    style={{
                      width: "100%",
                      maxHeight: "800px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </section>
        )}

        <section className="section-home-about bg-padma">
          <div className="container">
            <div className="home-about">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="heading" style={{ textAlign: "center" }}>
                    {blogs.title}
                  </h2>
                  <hr className="lebar" />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blogs.content || "",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else if (blogs?.category_id === "2") {
    return (
      <>
        {slides.length > 0 && (
          <section className="section-slider">
            <Slider {...settings}>
              {slides.map((slide, index) => (
                <div key={index} className="slide">
                  <img
                    src={slide.image_src}
                    alt={`Slide ${index + 1}`}
                    className="slide-image"
                    style={{
                      width: "100%",
                      maxHeight: "800px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </section>
        )}
        <section className="section-room-detail">
          <div className="container">
            <div className="room-detail_tab">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="text">
                    <h2 className="heading" style={{ textAlign: "center" }}>
                      {blogs.title}
                    </h2>
                    <hr className="lebar" />
                  </div>
                </div>
                <div className="col-md-4">
                  <ul className="room-detail_tab-header">
                    {tabs.map((tab) => (

                      <li key={tab.id} className={activeTab === tab.id.toString() ? "active" : ""}>
                        <a href="#" onClick={(e) => { e.preventDefault(); handleTabClick(tab.id.toString()); }} className="tab-button">
                          {tab.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-md-8">
                  <div className="room-detail_tab-content">
                    {tabs.length > 0 && tabs.map((tab) => (
                      activeTab === tab.id.toString() && (
                        <div key={tab.id} className="tab-pane active">
                          <div className="room-detail_overview" dangerouslySetInnerHTML={{ __html: tab.content }} />
                        </div>
                      )
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else if (blogs?.category_id === "3") {
    return (
      <>
        {slides.length > 0 && (
          <section className="section-slider">
            <Slider {...settings}>
              {slides.map((slide, index) => (
                <div key={index} className="slide">
                  <img
                    src={slide.image_src}
                    alt={`Slide ${index + 1}`}
                    className="slide-image"
                    style={{
                      width: "100%",
                      maxHeight: "800px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </section>
        )}
        <section className="section-home-about" style={{ paddingBottom: "100px" }}>
          <div className="container">
            <div className="home-about">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="heading" style={{ textAlign: "center" }}>FAQ</h2>
                  <hr className="lebar" />
                </div>

                {/* Left Column */}
                <div className="col-md-6">
                  {Array.isArray(leftColumn) && leftColumn.length > 0 ? (
                    // console.log(leftColumn),
                    leftColumn.map((category) => (
                      <div key={category.id} className="shortcode-ordered-list">
                        <h4 className="brown">{category.name}</h4>
                        <ul>
                          {Array.isArray(category.faq) && category.faq.length > 0 ? (
                            category.faq.map((faq) => (
                              <li key={faq.id}>
                                <button className="btn-collapse" onClick={() => handleCollapse(faq.id)}>
                                  {faq.question}
                                </button>
                                {openCollapse === faq.id && (
                                  <div className="collapse-content">
                                    <p>{faq.answer}</p>
                                  </div>
                                )}
                              </li>
                            ))
                          ) : (
                            <li>Tidak ada FAQ tersedia</li>
                          )}
                        </ul>
                      </div>
                    ))
                  ) : (
                    <p>Data kategori tidak tersedia</p>
                  )}
                </div>

                {/* Right Column */}
                <div className="col-md-6">
                  {Array.isArray(rightColumn) && rightColumn.length > 0 ? (
                    rightColumn.map((category) => (
                      <div key={category.id} className="shortcode-ordered-list">
                        <h4 className="brown">{category.name}</h4>
                        <ul>
                          {Array.isArray(category.faq) && category.faq.length > 0 ? (
                            category.faq.map((faq) => (
                              <li key={faq.id}>
                                <button className="btn-collapse" onClick={() => handleCollapse(faq.id)}>
                                  {faq.question}
                                </button>
                                {openCollapse === faq.id && (
                                  <div className="collapse-content">
                                    {parse(faq.answer || "")}
                                  </div>
                                )}
                              </li>
                            ))
                          ) : (
                            <li>Tidak ada FAQ tersedia</li>
                          )}
                        </ul>
                      </div>
                    ))
                  ) : (
                    <p>Data kategori tidak tersedia</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

      </>

    );
  } else if (blogs?.category_id === "4") {
    return (
      <>
        {slides.length > 0 && (
          <section className="section-slider">
            <Slider {...settings}>
              {slides.map((slide, index) => (
                <div key={index} className="slide">
                  <img
                    src={slide.image_src}
                    alt={`Slide ${index + 1}`}
                    className="slide-image"
                    style={{
                      width: "100%",
                      maxHeight: "800px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </section>
        )}
        <section className="section-map">
          <h1 className="element-invisible">Map</h1>
          <div className="contact-map">
            <div className="google-maps">
              <iframe
                src={blogs?.content} // Ganti dengan embed URL Google Maps Anda
                width="100%"
                height="700"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy" // Tambahkan loading lazy untuk performa
                referrerPolicy="no-referrer-when-downgrade" // Tambahkan referrer policy untuk keamanan
              ></iframe>
            </div>
          </div>
        </section>
        {/* END / MAP */}

        {/* CONTACT */}


        <section className="section-contact">
          <div className="container">
            <div className="contact">
              <div className="row">
                {
                  tabs.map((tab) => (
                    <div className="col-md-6 col-lg-5">
                      <h2 className="heading">{tab.label}</h2>
                      <div className="contact-location" style={{ paddingTop: 0 }}>
                        <div className="text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: tab.content || "",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>
            </div>
          </div>
        </section>
        {/* END / CONTACT */}
      </>
    );
  }

  return null;
};

export default Page;
