import axios from "axios";

const api = axios.create({
  baseURL: "https://api.padmaresident.com/api",
});


const token = localStorage.getItem("token");

export const cekToken = async (token) => {
  try {
    const response = await api.get("/check-token", {
      headers: {
        Authorization: `Bearer ${token}`, // Pastikan token benar
        "Content-Type": "application/json",
      },
    });

    return response?.data?.valid || false;
  } catch (error) {
    console.error("Error token:", error.response?.data || error.message);
    return false;
  }
};


export const fetchUsers= async ()=>{
  try {
    const response = await api.get("/user", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    return null;
  }
}

export const storeUser = async (formData) => {
  try {
    
    const response = await api.post("/user", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error Save User:", error);
    return null;
  }
};

export const updateUser = async (id, formData) => {
  try {
    const response = await api.put(`/user/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error Update Slide:", error);
    return null;
  }
};

export const fetchAllBlogs = async ()=> {
  try {
    const response = await api.get(`/blog`);
    return response?.data.data;
  } catch (error) {
    console.error("Error fetching blogs:", error);
    return null;
  }
};

export const fetchBlogs = async (id) => {
  try {
    const response = await api.get(`/blog/${id}`);
    return response?.data;
  } catch (error) {
    console.error("Error fetching blogs:", error);
    return null;
  }
};

export const fetchBlogBySlug = async (slug) => {
  try {
    const response = await api.get(`/blog/search/${slug}`);
    return response?.data;
  } catch (error) {
    console.error("Error fetching blog by slug:", error);
    return null;
  }
};


export const postSlide = async (formData) => {
  try {
    
    const response = await api.post("/slide", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error Upload Slide:", error);
    return null;
  }
};

export const updateSlide = async (id, formData) => {
  try {
    const response = await api.put(`/slide/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error Update Slide:", error);
    return null;
  }
};
export const delSlide = async ( id) => {


  try {
    const response = await api.delete(`/slide/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });


     return {
      success: true,
      message: response?.data?.message || "Data deleted successfully",
      response: response?.data,
    };
  } catch (error) {
    console.error("Error delete data:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to delete data",
      error,
    };
  }
};


export const fetchFaq = async () => {
  try {
    const response = await api.get(`/faq`);
    // console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error fetching tab by blog id:", error);
    return null;
  }
};

export const fetchFaqCategory = async () => {
  try {
    const response = await api.get(`/faqCategory`);
    // console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error fetching tab by blog id:", error);
    return null;
  }
};

export const postFaq = async (dataBlog) => {
  try {
    const response = await api.post("/faq", dataBlog, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error posting FAQ:", error);
    return null;
  }
};
// Fungsi untuk menyimpan blog
export const updateFaq = async (id, dataBlog) => {
  try {
    const response = await api.post(`/faq/update/${id}`, dataBlog, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error update blog:", error);
    return null;
  }
};

export const fetchFaqC = async () => {
  try {
    const response = await api.get(`/faqCategory`);
    // console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error fetching tab by blog id:", error);
    return null;
  }
};

export const postFaqC = async (dataBlog) => {
  try {
    const response = await api.post("/faqCategory", dataBlog, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error posting FAQ:", error);
    return null;
  }
};
// Fungsi untuk menyimpan blog
export const updateFaqC = async (id, dataBlog) => {
  try {
    const response = await api.post(`/faqCategory/update/${id}`, dataBlog, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error update blog:", error);
    return null;
  }
};

export const fetchTabAll = async () => {
  try {
    const response = await api.get(`/tabpage`);
    // console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error fetching tab by blog id:", error);
    return null;
  }
};

export const postTab = async (formData) => {
  try {
    const response = await api.post(`/tabpage`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error post tab", error);
    return null;
  }
};

export const fetchTabById = async (id) => {
  try {
    const response = await api.get(`/tabpage/show/${id}`);
    // console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error update tab", error);
    return null;
  }
};

export const fetchTab = async (id) => {
  try {
    const response = await api.get(`/tabpage/${id}`);
    // console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error fetching tab by blog id:", error);
    return null;
  }
};

export const updateTab = async (id, formData) => {
  try {
      const token = localStorage.getItem('token');
      const response = await api.post(`/tabpage/update/${id}`, formData, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      });
      return response.data;
  } catch (error) {
      console.error('Error updating tab:', error);
      throw error;
  }
};

export const deleteTabs = async (id) => {
  if (!token) {
    console.error("Error: Token tidak ditemukan!");
    return { success: false, message: "Token tidak ditemukan" };
  }
  const formattedToken = token.startsWith("Bearer ") ? token : `Bearer ${token}`;
  try {
    const response= await api.delete(`/tabpage/${id}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
     return {
      success: true,
      message: response?.data?.message || "Data deleted successfully",
      response: response?.data,
    };
  } catch (error) {
    console.error("Error delete data:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to delete data",
      error,
    };
  }
};


// Fungsi untuk menyimpan blog
export const postBlog = async (dataBlog) => {
  try {
    // console.log("Data Blog sebelum dikirim:", dataBlog);
    const response = await api.post("/blog", dataBlog, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error posting blog:", error);
    throw error; 
  }
};

export const deleteBlog = async (id) => {
 
  try {
    
    const response=await api.delete(`/api/blog/delete/${id}`, {
         method: 'POST',
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },

           
     });

     return {
      success: true,
      message: response?.data?.message || "Data deleted successfully",
      response: response?.data,
    };
  } catch (error) {
    console.error("Error delete data:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to delete data",
      error,
    };
  }
};

// Fungsi untuk menyimpan blog
export const updateBlog = async (id, dataBlog) => {
  try {
    const response = await api.post(`/blog/update/${id}`, dataBlog, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error update blog:", error);
    return null;
  }
};


export const fetchCategory = async () => {
  try {
    const response = await api.get(`/category`);
    return response?.data;
  } catch (error) {
    console.error("Error fetching blogs:", error);
    return null;
  }
};



// Fungsi untuk menyimpan blog
export const postCategory= async (dataCategory) => {
  try {
    const response = await api.post("/category", dataCategory, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "Failed to create data",
      error,
    };
  }
};

export const updateCategory = async (id, dataCategory) => {
  try {
    const response = await api.post(`/category/update/${id}`, dataCategory, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
   
      return {
        success: true,
        message: response?.data?.message || "Data updated successfully",
        data: response?.data,
      };
   
    
  } catch (error) {
    console.error("Error updating menu:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to update data",
      error,
    };
  }
};


// Fungsi untuk menyimpan blog
export const DelCategory= async ( id) => {
  
  try {
    const response = await api.post(`/category/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error delete data bro:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to update data",
      error,
      
    };
  }
};



export const fetchAllMenu = async ()=> {
  try {
    const response = await api.get(`/menu`);
    return response;
  } catch (error) {
    console.error("Error fetching menu:", error);
    return null;
  }
};


export const createMenu = async (dataMenu) => {
  try {
    const response = await api.post(`/menu`, dataMenu, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
   
      return {
        success: true,
        message: response?.data?.message || "Menu create successfully",
        data: response?.data,
      };
   
    
  } catch (error) {
    console.error("Error create menu:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to create menu",
      error,
    };
  }
};
export const updateMenu = async (id, dataMenu) => {
  try {
    const response = await api.post(`/menu/update/${id}`, dataMenu, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
   
      return {
        success: true,
        message: response?.data?.message || "Menu updated successfully",
        data: response?.data,
      };
   
    
  } catch (error) {
    console.error("Error updating menu:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to update menu",
      error,
    };
  }
};
export const delMenu = async (id) => {
  try {
    const response = await api.post(`/menu/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
   
      return {
        success: true,
        message: response?.data?.message || "Menu delete successfully",
        data: response?.data,
      };
   
    
  } catch (error) {
    console.error("Error delete menu:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to delete menu",
      error,
    };
  }
};


export const sendlogin=async(email, password)=>{
  try {
    const response = await api.post("/login", {
      email: email, // Data dikirim sebagai object, bukan di dalam 'body'
      password: password,
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data; // Akses data dengan response.data

  } catch (error) {
    console.error("Error login:", error);
  }
}
export const sendlogout=async()=>{
  try {
    const response = await api.post("/logout", {
      // email: email, // Data dikirim sebagai object, bukan di dalam 'body'
      // password: password,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data; // Akses data dengan response.data

  } catch (error) {
    console.error("Error logout:", error);
  }
}

export const fetchSetting = async ()=> {
  try {
    const response = await api.get(`/setting`);
    return response?.data;
  } catch (error) {
    console.error("Error fetching setting:", error);
    return null;
  }
};


export const createSetting = async (setting) => {
  try {
    const response = await api.post(`/setting`, setting, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
   
      return {
        success: true,
        message: response?.data?.message || "create successfully",
        data: response?.data,
      };
   
    
  } catch (error) {
    console.error("Error create serting:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to create menu",
      error,
    };
  }
};
export const updateSetting = async (id, setting) => {
  try {
    const response = await api.put(`/setting/${id}`, setting, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
   
      return {
        success: true,
        message: response?.data?.message || "Setting updated successfully",
        data: response?.data,
      };
   
    
  } catch (error) {
    console.error("Error updating setting:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Failed to update setting",
      error,
    };
  }
};



